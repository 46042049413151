<div class="w-full">
  <input
    #inputField
    class="mb-0 ml-2 box-border w-full border-0 bg-transparent text-sm font-light text-black focus:outline-none focus:ring-0"
    [ngClass]="{ 'placeholder-red-600': !control.valid }"
    [placeholder]="placeholder"
    [formControl]="control"
    type="text"
  />
</div>
