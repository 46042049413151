<div class="outline-field" [class.white-background]="whiteBackground">
  <div class="flex justify-between md:flex-row">
    <div>
      <h3
        [appTooltip]="tooltipText"
        tooltipPlacement="right"
        [tooltipVisible]="!!tooltipText"
      >
        {{ title }}
        <span
          class="text-xs text-gray-500"
          *ngIf="isRequiredField(control) && showRequiredIndicator"
          >*</span
        >
        <svg
          [appTooltip]="infoText || ''"
          tooltipPlacement="top"
          [tooltipVisible]="!!infoText"
          *ngIf="infoText"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="inline-flex size-3 text-gray-500"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
          />
        </svg>

        <em
          ><font size="-1.5">{{ optional }}</font></em
        >
      </h3>
      <span class="subtitle">{{ hint }}</span>
    </div>
    <button
      *ngIf="btnText"
      (click)="btnClick()"
      class="primary-btn btn-sm flex items-center justify-center gap-x-0.5 max-sm:max-w-32 max-sm:px-1 max-sm:py-0.5 max-sm:text-[10px] sm:gap-x-1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        class="size-7 sm:size-4"
      >
        <path
          fill-rule="evenodd"
          d="M5 4a.75.75 0 0 1 .738.616l.252 1.388A1.25 1.25 0 0 0 6.996 7.01l1.388.252a.75.75 0 0 1 0 1.476l-1.388.252A1.25 1.25 0 0 0 5.99 9.996l-.252 1.388a.75.75 0 0 1-1.476 0L4.01 9.996A1.25 1.25 0 0 0 3.004 8.99l-1.388-.252a.75.75 0 0 1 0-1.476l1.388-.252A1.25 1.25 0 0 0 4.01 6.004l.252-1.388A.75.75 0 0 1 5 4ZM12 1a.75.75 0 0 1 .721.544l.195.682c.118.415.443.74.858.858l.682.195a.75.75 0 0 1 0 1.442l-.682.195a1.25 1.25 0 0 0-.858.858l-.195.682a.75.75 0 0 1-1.442 0l-.195-.682a1.25 1.25 0 0 0-.858-.858l-.682-.195a.75.75 0 0 1 0-1.442l.682-.195a1.25 1.25 0 0 0 .858-.858l.195-.682A.75.75 0 0 1 12 1ZM10 11a.75.75 0 0 1 .728.568.968.968 0 0 0 .704.704.75.75 0 0 1 0 1.456.968.968 0 0 0-.704.704.75.75 0 0 1-1.456 0 .968.968 0 0 0-.704-.704.75.75 0 0 1 0-1.456.968.968 0 0 0 .704-.704A.75.75 0 0 1 10 11Z"
          clip-rule="evenodd"
        />
      </svg>
      {{ btnText }}
    </button>
  </div>

  <input
    [formControl]="control"
    *ngIf="!largeText && !location"
    [class.error]="!control.valid && submitted"
    [class.input-icon]="hasIcon"
    [type]="type"
    [placeholder]="placeholder"
    [appTooltip]="tooltipText"
    [tooltipPlacement]="tooltipPostion"
    [tooltipVisible]="!!tooltipText"
    (keypress)="isNumberKey($event)"
  />
  <div
    *ngIf="!largeText && !location && suffix"
    class="pointer-events-none relative inset-y-0 right-0 top-[-29px] mb-[-20px] ml-auto flex items-center pr-3"
  >
    <span
      [class.!text-gray-400]="disabled"
      class="text-gray-500 sm:text-sm"
      id="price-currency"
      >{{ suffix }}</span
    >
  </div>

  <ng-template [ngIf]="largeText && !location" #textArea>
    <textarea
      style="height: 100px"
      [formControl]="control"
      [class.error]="!control.valid && submitted"
      type="text"
      rows="4"
      cols="50"
      [appTooltip]="tooltipText"
      [tooltipPlacement]="tooltipPostion"
      [tooltipVisible]="!!tooltipText"
      [placeholder]="placeholder"
    >
    </textarea>
    <p
      class="error-text"
      *ngIf="!control.valid && submitted && control.hasError('required')"
    >
      This field is required
    </p>
    <p
      class="error-text"
      *ngIf="!control.valid && submitted && control.hasError('maxlength')"
    >
      The maximum length for this field is
      {{ control.errors?.["maxlength"].requiredLength }} characters.
    </p>
  </ng-template>

  <ng-template [ngIf]="location" #locationInput>
    <app-location-autocomplete
      [isSingleLine]="true"
      [control]="control"
      [placeholder]="placeholder"
      (locationOut)="retrieveLocation($event)"
      [error]="!control.valid && submitted"
    ></app-location-autocomplete>
  </ng-template>

  <ng-container *ngIf="!largeText">
    <p
      class="error-text"
      *ngIf="!control.valid && submitted && control.hasError('required')"
    >
      This field is required
    </p>
    <p
      class="error-text"
      *ngIf="
        !control.valid &&
        submitted &&
        !control.hasError('required') &&
        control.hasError('invalidUrl')
      "
    >
      Please enter a valid url
    </p>
    <p
      class="error-text"
      [class.iconFix]="hasIcon"
      *ngIf="!control.valid && control.hasError('invalidSalary')"
    >
      Invalid Range. Please recheck
    </p>
    <p
      class="error-text"
      [class.iconFix]="hasIcon"
      *ngIf="!control.valid && control.hasError('negativeSalary')"
    >
      Salary cannot be negative. Please recheck
    </p>
    <p
      class="error-text"
      *ngIf="
        !control.valid &&
        submitted &&
        !control.hasError('required') &&
        control.hasError('invalidUrlList')
      "
    >
      Please enter a valid list of emails
    </p>

    <p
      class="error-text"
      *ngIf="!control.valid && submitted && control.hasError('maxlength')"
    >
      The maximum length for this field is
      {{ control.errors?.["maxlength"].requiredLength }} characters.
    </p>
    <p
      class="error-text"
      *ngIf="!control.valid && submitted && control.hasError('min')"
    >
      The minimum value for this field is
      {{ control.errors?.["min"]?.min }}.
    </p>
  </ng-container>
</div>
