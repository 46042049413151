import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";

import { GoogleMapsService } from "src/app/core/services/google-maps/google-maps.service";
import { LocationParserService } from "src/app/core/services/location-parser/location-parser.service";
import { TooltipDirective } from "../../../directives/tooltip.directive";

export interface ILocation {
  city: string;
  state: string;
  country: string;
  isSingleLine?: string;
}

@Component({
  selector: "app-location-autocomplete",
  templateUrl: "./location-autocomplete.component.html",
  standalone: true,
  imports: [CommonModule, TooltipDirective, FormsModule, ReactiveFormsModule],
  styleUrls: ["./location-autocomplete.component.scss"],
})
export class LocationAutocompleteComponent implements OnInit {
  @ViewChild("inputField", { static: false }) inputField?: ElementRef;

  @Input() placeholder: string = "No City Selected";
  @Input() control: FormControl = new FormControl("");
  @Input() isSingleLine: boolean = false;
  @Input() public error: boolean = false;

  @Output() locationOut: EventEmitter<ILocation> =
    new EventEmitter<ILocation>();

  public cityError: boolean = false;
  public location?: ILocation;
  private autocomplete?: google.maps.places.Autocomplete;

  constructor(
    private googleMapsService: GoogleMapsService,
    private locationParserService: LocationParserService,
  ) {}

  ngOnInit(): void {
    this.googleMapsService
      .loadGoogleMaps()
      .then(() => this.initializeAutocomplete())
      .catch((error: Error) =>
        console.error("Google Maps API failed to load", error),
      );
  }
  public focusInputField(): void {
    if (this.inputField) {
      this.inputField.nativeElement.focus();
    }
  }
  private initializeAutocomplete(): void {
    if (this.inputField?.nativeElement) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.inputField.nativeElement,
        {
          fields: ["address_components", "formatted_address"],
        },
      );
      this.autocomplete.addListener("place_changed", () =>
        this.handlePlaceChanged(),
      );
    }
  }

  private async handlePlaceChanged(): Promise<void> {
    const place = this.autocomplete?.getPlace();
    if (!place?.address_components) return;

    this.location = this.locationParserService.parsePlace(place);
    if (!this.isSingleLine && !this.location.city) {
      this.placeholder = "City not Selected. Please select a city!";
      this.cityError = true;
    } else {
      this.cityError = false;
    }

    this.updateInputField();
    this.locationOut.emit(this.location);
  }

  private updateInputField(): void {
    if (this.isSingleLine && this.inputField) {
      this.inputField.nativeElement.value = this.location?.isSingleLine || "";
    }
  }
}
