import { AbstractControl, FormControl } from '@angular/forms';

export function ValidateUrl(control: AbstractControl): { invalidUrlList: boolean } | null {
  const regex = /\S+@\S+\.\S+/;
  const emails = control.value?.split(',').map((v: string) => v.trim());
  const res = emails?.every((email: string) => {
    if (regex.test(email)) return true;
    return false;
  });

  if (res) return null;
  return { invalidUrlList: true };
}

export function isRequiredField(control: FormControl): boolean {
  if (!control.validator) {
    return false;
  }

  const validator = control.validator({} as AbstractControl);
  return validator && validator['required'];
}
