import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  HostListener,
  OnInit,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[appTooltip]',
  standalone: true,
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input() appTooltip: string = '';
  @Input() tooltipPlacement: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() tooltipVisible: boolean = true;

  private tooltipElement?: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.createTooltip();
  }
  ngOnDestroy(): void {
    this.hideTooltip();
  }

  private createTooltip(): void {
    this.tooltipElement = this.renderer.createElement('span');
    this.renderer.appendChild(this.tooltipElement, this.renderer.createText(this.appTooltip));
    this.renderer.appendChild(document.body, this.tooltipElement);

    this.renderer.addClass(this.tooltipElement, 'tooltip');
    this.renderer.addClass(this.tooltipElement, `tooltip-${this.tooltipPlacement}`);
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.tooltipVisible) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.hideTooltip();
  }

  private showTooltip(): void {
    if (!this.tooltipElement) return;

    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltipElement.getBoundingClientRect();

    const scrollPos =
      window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left;

    switch (this.tooltipPlacement) {
      case 'top':
        top = hostPos.top - tooltipPos.height + scrollPos - 6; // Adjust for arrow height
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        break;
      case 'bottom':
        top = hostPos.bottom + scrollPos + 6; // Adjust for arrow height
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        break;
      case 'left':
        top = hostPos.top + (hostPos.height - tooltipPos.height) / 2 + scrollPos;
        left = hostPos.left - tooltipPos.width - 6; // Adjust for arrow width
        break;
      case 'right':
        top = hostPos.top + (hostPos.height - tooltipPos.height) / 2 + scrollPos;
        left = hostPos.right + 6; // Adjust for arrow width
        break;
    }

    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
  }

  private hideTooltip(): void {
    this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
  }
}
