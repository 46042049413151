import { Injectable } from "@angular/core";

import { ILocation } from "src/app/shared/components/input-groups/location-autocomplete/location-autocomplete.component";

@Injectable({
  providedIn: "root",
})
export class LocationParserService {
  public parsePlace(place: google.maps.places.PlaceResult): ILocation {
    let city = "";
    let state = "";
    let country = "";

    place.address_components?.forEach(
      (component: google.maps.GeocoderAddressComponent) => {
        const componentType = component.types[0];
        switch (componentType) {
          case "locality":
            city = component.long_name;
            break;
          case "administrative_area_level_1":
            state = component.long_name;
            break;
          case "country":
            country = component.long_name;
            break;
        }
      },
    );

    return {
      city,
      state,
      country,
      isSingleLine: place.formatted_address ?? "",
    };
  }
}
