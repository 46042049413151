import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { IToast } from './component/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastConfig: Subject<IToast> = new Subject<IToast>();

  public showToast(message: string): void {
    this.toastConfig.next({ message, type: 'normal' });
  }

  public showSuccessToast(message: string): void {
    this.toastConfig.next({ message, type: 'success' });
  }

  public showErrorToast(message: string): void {
    this.toastConfig.next({ message, type: 'error' });
  }
}
