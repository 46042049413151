import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";

import { TooltipDirective } from "src/app/shared/directives/tooltip.directive";
import { isRequiredField } from "src/app/core/utils/form.utils";
import {
  ILocation,
  LocationAutocompleteComponent,
} from "../location-autocomplete/location-autocomplete.component";

@Component({
  selector: "app-input",
  standalone: true,
  imports: [
    CommonModule,
    TooltipDirective,
    FormsModule,
    ReactiveFormsModule,
    LocationAutocompleteComponent,
  ],
  templateUrl: "./input.component.html",
  styleUrl: "./input.component.scss",
})
export class InputComponent implements OnInit, OnChanges {
  @Input() largeText: boolean = false;
  @Input() location: boolean = false;
  @Input() btnText: string = "";
  @Input() whiteBackground: boolean = true;
  @Input() submitted: boolean = false;
  @Input() infoText?: string;
  @Input() title = "";
  @Input() hint = "";
  @Input() placeholder = "";
  @Input() tooltipText = "";
  @Input() tooltipPostion: "top" | "bottom" | "left" | "right" = "right";
  @Input() optional = "";
  @Input() type: "text" | "number" = "text";
  @Input() numberOnly: boolean = false;
  @Input() suffix: string = "";
  @Input() hasIcon = false;
  @Input() control: FormControl = new FormControl("");
  @Input() disabled: boolean = false;
  @Input() showLoader: boolean = false;
  @Input() showRequiredIndicator: boolean = false;

  @Output() locationOutput: EventEmitter<string> = new EventEmitter<string>();
  @Output() buttonControl: EventEmitter<void> = new EventEmitter<void>();

  public retrieveLocation(location: ILocation): void {
    this.locationOutput.emit(location.isSingleLine);
  }

  public isRequiredField: (control: FormControl<any>) => boolean =
    isRequiredField;

  public btnClick(): void {
    this.buttonControl.emit();
  }

  ngOnInit(): void {
    if (this.disabled) this.control.disable();
  }

  ngOnChanges(): void {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  public isNumberKey(event: KeyboardEvent): void {
    if (this.numberOnly) {
      const charCode = event.which ? event.which : event.keyCode;
      // Allow: backspace, tab, enter and numbers
      if (
        charCode !== 8 &&
        charCode !== 9 &&
        charCode !== 13 &&
        (charCode < 48 || charCode > 57)
      ) {
        event.preventDefault();
      }
    }
  }
}
