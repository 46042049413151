import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoogleMapsService {
  private mapsLoaded: boolean = false;
  private mapsLoadPromise: Promise<void> | null = null;

  constructor() {}

  public loadGoogleMaps(): Promise<void> {
    if (this.mapsLoaded) {
      return Promise.resolve();
    }

    if (this.mapsLoadPromise) {
      return this.mapsLoadPromise;
    }

    this.mapsLoadPromise = new Promise((resolve, reject) => {
      const script = document.createElement("script");

      // Set the src, async, defer, and loading attributes
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.google_API_key}&libraries=places&v=weekly&callback=initMap&loading=async`;
      script.setAttribute("loading", "async");
      script.defer = true;

      document.head.appendChild(script);

      // Explicitly declare `initMap` on the window object
      (window as any).initMap = async () => {
        this.mapsLoaded = true;
        resolve();
      };

      script.onerror = (error: any) => {
        reject(error);
      };
    });

    return this.mapsLoadPromise;
  }
}
